$font-family-sans-serif: 'Raleway', sans-serif;

.doc_catalog {
    min-height: 400px;
}

.doc_catalog h2 {
    text-align: left;
    font: 300 23px/28px $font-family-sans-serif;
    letter-spacing: 2.65px;
    text-transform: uppercase;
    margin-top: 1.5rem;
}

.doc_catalog h2 b {
    text-align: left;
    font: 600 17px/28px $font-family-sans-serif;
    letter-spacing: 2.65px;
    text-transform: uppercase;
}

.doc_catalog h2 img {
    margin: 1rem 1rem 1rem 0;
}

.doc_catalog h2:hover {
    cursor: pointer;
}

.doc_catalog .title_underline {
    margin-top: -1rem;
    top: 915px;
    left: 157px;
    width: 100%;
    height: 2px;

    border-top: solid 3px white;
    margin-bottom: 3rem;
}

.doc_catalog .category_anchor {
    display: block;
    position: relative;
    top: -120px;
    visibility: hidden;
}

.doc_catalog .shop_anchor {
    display: block;
    position: relative;
    top: 0px;
    visibility: hidden;
}

.doc_catalog .medium_size_card {
    box-shadow: none;
    border: 1px solid black;
    border-radius: 10px;
    width: 100%;

    min-height: 130px;
    height: 100%;

    text-align: left;
    font: 400 18px/19px $font-family-sans-serif;
    letter-spacing: 0px;

    background-color: #231f20;
    background-repeat: no-repeat;
    background-position: top right;
    color: #FFFFFF;

    padding: 0;
    margin: 5px;

    cursor: pointer;
    overflow: hidden;
}

/*.medium_size_card::before {
    content: "";
    position: absolute;
    height: 100px;
    width: 100px;
    top: -58px;
    left: -88px;
    z-index: 1;
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8' standalone='yes'?%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Square' viewbox='0 0 99 99' width='100' height='100'%3E%3Crect id='blue_square' fill='%2304D98B' x='0' y='0' width='99' height='99' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transform: rotate(45deg);
}
*/
.doc_catalog .card .card-body {
    padding: 3rem 1.5rem 0 2rem;
    //padding: 3rem 5rem 0 2rem;
    
}

.doc_catalog .card .card-footer {
    text-align: right;
    padding: 0 1.5rem 1.3rem 1.5rem;
}

.doc_catalog .medium_size_card .h5 {
    text-align: left;
    float: left;
    font: 400 14px $font-family-sans-serif;
    letter-spacing: 0px;
    color: #474747;
    margin: 5px 20px;
}

.doc_catalog .card .card-body hr {
    border-top: 1px solid #707070;
    margin-top: 0;
}

.doc_catalog .medium_size_card .price {
    text-align: right;
    font: 800 24px/19px $font-family-sans-serif;
    letter-spacing: 0px;
    color: #00AFDC;
    /*margin-top: 8px;*/
}

.doc_catalog .card .card-footer {
    background-color: transparent;
    border: 0;
}

.doc_catalog .card .img_shadow {
    margin-bottom: 1em;
    margin-top: -2em;
}

.doc_catalog .card .img_shadow ellipse {
    fill: #cdc4d9;
}

.doc_catalog .col-lg-4 {
    padding: 0;
}

.mdps_banner {
    text-align: center;
    padding: 21px;
    background-color: white;
}

.shop_section_title h1 {
    text-align: left;
    //font: 300 42px/42px "Raleway", sans-serif;
    font: 300 32px/32px "Raleway", sans-serif;
    letter-spacing: 2.65px;
    margin-bottom: 2rem;
}

.shop_section_title b {
    font-weight: 600;
}

.doc_catalog_u {
    color: #ffffff;

    padding-top: 6rem;
    padding-bottom: 1rem;
}

.doc_catalog_u h1 {
    padding-bottom: 1rem;
}

.doc_catalog_u .medium_size_card {
    background-color: #f6f6f6;
    //background-image: url("/img/store/decoCardDark1.png");
    background-image: none;
    background-repeat: no-repeat;
    background-position: top right;
    color: #313131;
}

.doc_catalog_hr .card {
    background-color: #231f20;
    background-image: url("../../img/decoCardDark1.png");
}
.doc_catalog_others .card {
    background-color: #434343;
    background-image: url("../../img/decoCardDark2.png");
}

.shop_documents {
    background: transparent linear-gradient(180deg, #313131 0%, #1a1a1a 255px, #030303 510px) 0% 0% no-repeat padding-box;
    margin-bottom: 4rem;
}

.shop .poweredBy {
    top: 1.4rem;
    color: #313131;
}

.shop .down {
    margin-top: 3px;
}

.shop .right {
    margin-top: 8px;
}

.shop .up {
    margin-top: 8px;
}

.document_card_anchor:hover {
    text-decoration: none;
}