/* latin */
@font-face {
    font-family: "Raleway";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4Y_LDrMfIA.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtaorCIPrE.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaorCIPrE.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoorCIPrE.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpbCIPrE.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pbCIPrE.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapbCIPrE.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
    font-family: "Roboto Mono";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vq_ROW4.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
        U+2215, U+FEFF, U+FFFD;
}

$dropdown-link-color: white;

$dropdown-bg: #313131;
$dropdown-link-active-color: white;
$dropdown-link-active-bg: #313131;
$dropdown-link-hover-bg: #313131;
$dropdown-link-hover-color: #00afdc;

$enable-shadows: true;
$modal-content-color: #313131;
$modal-content-bg: #ffffff;
/*$modal-content-border-color: black;*/
/*
$modal-content-box-shadow-xs: 0 .25rem .5rem rgba(4, 217, 139, .5);
$modal-content-box-shadow-sm-up: 0 .5rem 1rem rgba(4, 217, 139, .5);*/
$modal-content-border-radius: 20px;

$btn-border-radius: 10px;
$btn-border-radius-lg: 10px;
$btn-border-radius-sm: 10px;

$theme-colors: (
    "primary": #140126,
    "danger": #ff2943,
    "dark": #313131,
    "success": #00afdc,
);

$font-family-sans-serif: "Raleway", sans-serif;
$font-family-monospace: "Roboto Mono", monospace;

@import "~bootstrap/scss/bootstrap";

$body-bg: #030303;
$body-color: #ffffff;

body {
    font-display: swap;
}

.navbar {
    box-shadow: 0px 3px 10px #0000007a;
    min-height: 77px;
    display: block;
}

.nav-item {
    margin-right: 1em;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("./img/navToggler.png");
    width: 24px;
    height: 24px;
}

.navbar-dark .navbar-toggler {
    border: none;
}

.navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
    opacity: 0.69;

    //padding-bottom: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    text-transform: uppercase;
}

.navbar-dark .navbar-nav .nav-link.active {
    color: #ffffff;
    opacity: 1;
}

.navbar_spacer {
    height: 60px;
}

.dropdown-divider {
    margin: 0.5rem;
}

#toc-navbar-nav {
    padding-left: 57px;
}

.site_content {
    padding: 0;
    //background-color: white;
}

.form-control {
    font-family: "Roboto Mono", monospace;
    font-size: 0.9rem;
}

.custom-select {
    font-family: "Roboto Mono", monospace;
    font-size: 0.9rem;
    color: #495057;
}

.form-control:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 0 0.2rem rgba(206, 212, 218, 0.55);
}

.working_modal .btn {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2.65px;

    color: #313131;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
}

.working_modal h5 {
    color: #00afdc;
    letter-spacing: 2.65px;
}

.btn {
    font: 400 16px "Raleway", sans-serif;
    box-shadow: none;
}

.btn-link {
    box-shadow: none !important;
    border-radius: 0;
}

.btn-block {
    text-transform: uppercase;
}

.btn-success {
    //color: #313131;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
}

.btn-outline-success {
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
}

/* Transiciones entre paginas */
.fade-enter {
    opacity: 0.01;
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 100ms ease-in;
}

/* Loading spinner */
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #313131;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #313131 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* Loading spinner 2 */
.bouncing_spinner {
    margin: auto;
    width: 70px;
    text-align: center;
}
.bouncing_spinner > div {
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.bouncing_spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.bouncing_spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}
@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* Sprites extra */
.elipseTopWelcome {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='687' height='687' viewBox='0 0 687 687'%3E%3Cdefs%3E%3Cstyle%3E.a{fill:%23140126;}%3C/style%3E%3C/defs%3E%3Ccircle class='a' cx='343.5' cy='343.5' r='343.5'/%3E%3C/svg%3E");
}

.elipseBottomWelcome {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='412' height='412' viewBox='0 0 412 412'%3E%3Cdefs%3E%3Cstyle%3E.a{fill:%23140126;}%3C/style%3E%3C/defs%3E%3Ccircle class='a' cx='206' cy='206' r='206'/%3E%3C/svg%3E");
}

.bottom_page {
    font: 600 17px/35px $font-family-sans-serif;
    padding: 2px;
    height: 39px;
    color: white;
    //background: transparent linear-gradient(90deg, #04D98B 0%, #260259 100%) 0% 0% no-repeat padding-box;
    background-color: #00afdc;
}

.bottom_page a {
    color: white;
}

.bottom_page a:hover {
    color: white;
}

/* Footer */
.site_footer {
    text-align: center;
    color: white;
    background-color: #030303;
}

.site_footer .footer_container {
    padding: 2rem;
}

.site_footer .company {
    padding: 3rem 0 3rem 0;

    background: #030303;

    text-align: left;
    font: 400 17px/35px $font-family-sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
}

.site_footer .company a {
    color: #ffffff;
}

.site_footer .siguenos {
    padding: 3rem 0 0 1rem;
    text-align: right;
    color: #ffffff;
}

.site_footer .siguenos img {
    margin: 0.2rem 0 0.2rem 0.2rem;
}

.site_footer .underlined {
    border-bottom: 1px solid #ffffff;
    min-height: 124px;
}

.site_footer .poweredRow {
    min-height: 200px;
}

.site_footer .mdp img {
    position: relative;
    top: 6rem;
    transform: translateY(-50%);
}

.site_footer .mdp span {
    position: relative;
    left: 15px;
}

.site_footer .mdp .cards {
    white-space: nowrap;
}

.site_footer .powered {
    margin-top: 2rem;
    font-weight: 500;
}

.site_footer .links {
    text-align: left;
    padding: 2rem 0 2.5rem 0;
    font: 300 14px/16px $font-family-sans-serif;
}

.site_footer .links .link {
    padding: 2rem 0 0 0;
}

.site_footer .links img {
    margin: 1rem 0 1rem 2rem;
}

.site_footer .links b {
    font: 600 17px/35px $font-family-sans-serif;
}

.site_footer .row {
    margin-left: 0;
    margin-right: 0;
}

.site_footer a {
    text-decoration: none;
    color: white;
}

.site_footer a:hover {
    text-decoration: none;
    color: white;
}

.banner_hashtag {
    background-color: #00afdc;
    height: 198px;
    overflow: hidden;

    letter-spacing: 15px;

    font-weight: 200;
    font-size: 35px;
    line-height: 190px;
}

/* Modal de ayuda */
.ayuda_modal .modal-header {
    border: 0;
}

.ayuda_modal .modal-footer {
    border: 0;
    height: 4rem;
}

.ayuda_modal .modal-body {
    padding: 0 1rem;
}

.ayuda_modal .close {
    outline: 0;
}

.ayuda {
    font-size: 0.8rem;
    color: #707070;
}

.ayuda_modal h2 {
    color: #260259;
    font-size: 28px;
    font-weight: bold;

    position: relative;
    left: -5rem;
    top: 2rem;
}

.ayuda_modal h3 {
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.ayuda p {
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 15px;
}

.ayuda ol li {
    margin-bottom: 0.7rem;
    margin-top: 0.7rem;
}

.boton_ayuda {
    margin: 0;
    padding: 0;
    box-shadow: none !important;
    text-transform: uppercase;
}

h1 {
    color: #00afdc;
    text-transform: uppercase;
}

.alert-info {
    background-color: #e2e2e2;
    background-image: url("./img/iconoAlertInfo.png");
    background-repeat: no-repeat;
    background-position: 5rem 1.1rem;

    color: #000000;
    border: 0;

    padding: 1rem 5rem 1rem 7rem;
}

.alert-info p {
    margin-bottom: 0;
    font-weight: bold;
}

.navbar-brand img {
    width: 250px;
    height: 28px;
}

@media (min-width: map-get($grid-breakpoints, "sm")) {
    .navbar-brand img {
        width: 278px;
        height: 31px;
    }

    .shop_section_title h1 {
        font: 300 42px/42px "Raleway", sans-serif;
    }
}

.fondo_degradado {
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' standalone='no'?%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 20010904//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg width='96' height='510' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='Gradient2' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0%25' stop-color='%23030303'/%3E%3Cstop offset='50%25' stop-color='%231a1a1a'/%3E%3Cstop offset='100%25' stop-color='%23313131'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' width='98' height='510' fill='url(%23Gradient2)'/%3E%3C/svg%3E");
    background-position: bottom left;
    background-repeat: repeat-x;
}

.poweredBy {
    font-size: 0.8rem;
}

.arrow {
    border: solid #f6f6f6;
    border-width: 0 2px 2px 0;
    display: block;
    padding: 0 6px 6px 0;
    margin: 3px;
    float: right;

    transition: all 200ms ease-in-out;
}

.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin: 3px 4px 3px 2px;
}

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin: 3px 2px 3px 4px;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    margin: 4px 3px 2px 3px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin: 2px 3px 4px 3px;
}

.arrow i {
    transform: translateX(-50%);
}

.linkLang {
    text-transform: uppercase;
    padding: 0;
}
.regionBar {
    margin: 0;

    color: lightgray;
    background-color: #313131;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    //border-bottom: solid 1px rgba(128, 128, 128, 0.2);
    border: 0;

    padding: 0.75rem 1.25rem;

    transition: all 200ms ease-in-out;
}

.regionBar .close {
    padding: 0 1.25rem;
}

.regionBar span {
    margin-top: 0.5rem;
}

.regionBar .btn {
    margin-top: 0px;
}

.regionBar .custom-select {
    margin-top: 4px;
}

.regionBar-enter {
    transform: translateY(-14vh);
}
.regionBar-enter-active {
    transform: translateY(0);
    transition: all 400ms ease-out;
}
.regionBar-exit {
    transform: translateY(0);
}
.regionBar-exit-active {
    opacity: 0;
    transform: translateY(-14vh);
    transition: all 50ms ease-in-out;
}

.page_loading {
    min-height: 50vh;
}
