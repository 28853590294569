.welcome {
    background-color: #9bd5e3;
    color: #313131;
}

.welcome .container {
    padding: 60px 0 40px 0;
    margin-bottom: 0;
}

.welcome h1 {
    font-size: 40px;
    line-height: 40px;
    color: #313131;
    text-transform: none;
    text-align: right;
}

.welcome p {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-align: right;
}

.welcome .title_underline {
    margin-top: 1rem;
    //margin-left: 2px;
    margin-bottom: 1rem;
    
    top: 915px;
    left: 157px;
    width: 100%;
    height: 6px;

    background: transparent linear-gradient(90deg, #04D98B 0%, #260259 100%) 0% 0% no-repeat padding-box;
}

.welcome .btn-block {
    z-index: 100;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    border-radius: 20px;
}

.tutorial_modal .modal-header {
    border: 0;
}

.tutorial_modal .modal-footer {
    border: 0;
    height: 4rem;
}

.tutorial_modal .modal-body {
    padding: 2rem 4rem;
}

.tutorial_modal .close {
    outline: 0;
    position: relative;
    top: 1rem;
    right: 2rem;
    font-size: 40px;
    font-weight: 400;
}

.tutorial {
    font-size: 0.8rem;
    color: #2D2D2D;
}

.tutorial h2 {
    color: #00AFDC;
    font-size: 30px;
    line-height: 25px;
    font-weight: 400;
    text-transform: uppercase;
}

.tutorial h3 {
    color: #00AFDC;
    font-size: 28px;
    line-height: 33px;
    font-weight: bold;
    
    position: relative;
    text-align: left;
    top: 2rem;
}

.tutorial p {
    color: #707070;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 15px;
    line-height: 18px;
}

.tutorial .col-lg {
    text-align: center;
}

.tutorial .col-xl {
    text-align: center;
}
